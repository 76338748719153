<template>
	<div v-visible.once="drawPage" :class="{ focused: isPageFocused }" class="pdf-thumbnail" @click="focusPage">
		<img v-if="src" :src="src" class="box-shadow" />
		<div v-else class="placeholder box-shadow">
			<div v-t="'loading'" class="content"></div>
		</div>
		<span class="page-number">{{ pageNumber }}</span>
	</div>
</template>

<script>
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'
import PDFPreview from '@/helpers/PDFPreview'

export default {
	name: 'PDFThumbnail',
	directives: {
		visible: PDFPreview.directives.visible
	},
	mixins: [DocumentVersionsModuleGuard],
	props: {
		page: {
			type: Object, // instance of PDFPageProxy returned from pdf.getPage
			required: true
		},
		scale: {
			required: true,
			type: Number
		},
		isPageFocused: {
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			src: undefined
		}
	},
	computed: {
		viewport: function () {
			return this.page.getViewport({ scale: 1.0 })
		},
		pageNumber: function () {
			return this.page.pageNumber
		}
	},
	watch: {
		page: function () {
			this.destroyPage()
		},
		src: function () {
			this.updateVisibility()
		},
		scale: function () {
			this.updateVisibility()
		}
	},
	beforeDestroy: function () {
		this.destroyPage(undefined, this.page)
	},
	methods: {
		focusPage: function () {
			this.$emit('page-focus', this.pageNumber)
		},
		drawPage: function () {
			if (this.renderTask) {
				return
			}
			const { viewport } = this
			const canvas = document.createElement('canvas')
			const canvasContext = canvas.getContext('2d')
			const renderContext = { canvasContext, viewport }
			canvas.height = viewport.height
			canvas.width = viewport.width
			this.renderTask = this.page.render(renderContext)
			this.renderTask.promise
				.then(() => {
					this.src = canvas.toDataURL()
					// Zeroing the width and height causes Firefox to release graphics
					// resources immediately, which can greatly reduce memory consumption.
					canvas.width = 0
					canvas.height = 0
					this.$emit('thumbnail-rendered', {
						page: this.page,
						text: `Rendered thumbnail ${this.pageNumber}`
					})
				})
				.catch(response => {
					this.destroyRenderTask()
					this.$emit('thumbnail-errored', {
						response,
						page: this.page,
						text: `Failed to render thumbnail ${this.pageNumber}`
					})
				})
		},
		destroyPage: function (_newPage, page) {
			// PDFPageProxy#_destroy
			// https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
			if (page) {
				page._destroy()
			}
			this.destroyRenderTask()
		},
		destroyRenderTask: function () {
			if (!this.renderTask) {
				return
			}
			// RenderTask#cancel
			// https://mozilla.github.io/pdf.js/api/draft/RenderTask.html
			this.renderTask.cancel()
			delete this.renderTask
		},
		updateVisibility: function () {
			this.$parent.$emit('update-visibility')
		}
	}
}
</script>

<style scoped>
.pdf-thumbnail {
	cursor: pointer;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	width: 100%;
}

img,
.placeholder {
	border: 7px solid transparent;
	border-radius: 5px;
	width: 65%;
}

.pdf-thumbnail.focused img {
	border-color: rgba(var(--v-secondary-transparent), 0.65);
}

.placeholder {
	background: white;
	background-clip: content-box;
	position: relative;
}

.placeholder:before {
	content: '';
	display: block;
	padding-top: 75%;
}

.placeholder .content {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.page-number {
	color: white;
	font-weight: bold;
}
</style>
